import { parseFullUsername } from '@/core/common/helpers/utils';
import store from '@/core/common/services/vuex-store';
import { GET_MSGRAPH_USERS } from '@/core/user/stores/auth.module.js';
export const GET_USERS = 'user.getUser';
export const SET_USER = 'user.setUser';
export const SET_TIME_UPDATED = 'user.setTimeUpdated';
const moment = require('moment');

const state = {
  users: [],
  timeUpdated: null,
  defaultUser: {
    businessPhones: [],
    displayName: 'Unbekannt',
    givenName: 'John',
    jobTitle: 'Softwareentwickler',
    mail: 'john.doe@skr.de',
    mobilePhone: null,
    officeLocation: null,
    preferredLanguage: null,
    surname: 'John',
    userPrincipalName: 'john.doe@skr.de',
    id: '000000-00000-0000-0000-00000000000',
  },
};
const actions = {
  [GET_USERS](context) {
    if (!context.state.timeUpdated || moment().diff(moment(context.state.timeUpdated), 'minutes') > 10) {
      return store.dispatch(GET_MSGRAPH_USERS).then(response => {
        context.commit(SET_USER, response);
        context.commit(SET_TIME_UPDATED, moment());
      });
    }
  },
};
const mutations = {
  [SET_USER](state, users) {
    state.users = [...users];
  },
  [SET_TIME_UPDATED](state, time) {
    state.timeUpdated = time;
  },
};
const getters = {
  getYieldFulfillmentTeamUsersIds: state => {
    return state.users
      .filter(user => user.department?.toLowerCase().includes('Yield Fulfillment'))
      .map(item => item.id);
  },
  getPmTeamUsersIds: state => {
    return state.users
      .filter(user => user.department?.toLowerCase().includes('produktmanagement'))
      .map(item => item.id);
  },
  getDomTeamUsersIds: state => {
    return state.users
      .filter(user => user.department === 'Destination Operations Management')
      .map(item => item.id);
  },
  getFlugTeamUsersIds: state => {
    return state.users.filter(user => user.department === 'Flug').map(item => item.id);
  },
  getCsmTeamUsersIds: state => {
    return state.users.filter(user => user.department === 'Customer Success Management').map(item => item.id);
  },
  getTechTeamUsersIds: state => {
    return state.users.filter(user => user.department === 'Tech').map(item => item.id);
  },
  getYieldControllingTeamUsersIds: state => {
    return state.users.filter(user => user.department === 'Yield Controlling').map(item => item.id);
  },
  getUsernameById: state => id => {
    const user = state.users.filter(user => user.id === id)[0];
    return user && parseFullUsername(user);
  },
  getJobTitleById: state => id => {
    const user = state.users.filter(user => user.id === id)[0];
    return user?.jobTitle;
  },
  getDepartmentById: state => id => {
    const user = state.users.filter(user => user.id === id)[0];
    return user?.department?.length > 10
      ? user.department
          .split(' ')
          .map(word => word[0])
          .join('')
      : user.department;
  },
  getUserById: state => id => {
    const usersById = state.users.filter(user => user.id === id)[0];
    return Array.isArray(usersById) && usersById.length > 0 ? usersById[0] : state.defaultUser;
  },
  getAllUsers(state) {
    return state.users;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
