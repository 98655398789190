const moment = require('moment');
import { format, add, subDays } from 'date-fns';
import regionsmanagerConfig from '@/core/flugverfuegbarkeit/regionsmanager-reisekuerzel.json';
import store from '@/core/common/services/vuex-store.js';

export function calculateReleaseStartDateDiff(releasedatum, startdatum) {
  if (releasedatum && startdatum) {
    const momentReleaseDate = moment(releasedatum);
    const momentStartDate = moment(startdatum);
    momentReleaseDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    momentStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return `(${momentStartDate.diff(momentReleaseDate, 'days')})`;
  } else {
    return '-';
  }
}

export const fernRegions = ['Asien', 'Amerika', 'Ozeanien', 'Afrika', 'Orient'];
export const euRegions = ['Südeuropa', 'Nordeuropa', 'Dach'];
export const calculateLastSaturday = () => {
  // Get the current date
  const currentDate = moment();
  // Calculate the difference in days from the last Saturday (6 for Saturday, 5 for Sunday, etc.)
  const daysUntilLastSaturday = (currentDate.day() + 1) % 7;

  // Subtract the difference to get the date of the last Saturday
  // !! BE CAREFUL MOMENT OBJECTS ARE MUTABLE, currentDate gets changed here
  const lastSaturday = currentDate.subtract(daysUntilLastSaturday, 'days');
  return lastSaturday.format('YYYY-MM-DD');
};
export const formatDate = date => {
  if (date) {
    return format(new Date(date), 'dd.MM.yyyy HH:mm');
  } else return '';
};
export function formatDateWithoutHours(date) {
  if (date) {
    return format(new Date(date), 'dd.MM.yyyy');
  } else return '';
}
export function areArraysEqualSets(a1, a2) {
  if (!a1 && !a2) {
    return true;
  }
  if (!a1 || !a2) {
    return false;
  }

  const superSet = {};
  for (const i of a1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of a2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (let e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }
  return true;
}

export function isRoleMatchingRouteRoles(userRoles, routeRoles) {
  // check if user has all roles required
  return userRoles.filter(role => routeRoles.includes(role)).length > 0;
}

export function isObjectJSONStringifyEqual(object1, object2) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function tryParseJSONString(str) {
  try {
    const parsedString = JSON.parse(str);
    return { isParsed: true, data: parsedString };
  } catch (e) {
    return { isParsed: false, data: str };
  }
}

export function isOptionsRefreshableByValueAndKey(value, key, currentFilterOptions, startSearchAtLength) {
  if (!currentFilterOptions) {
    return true;
  }
  const currentObjectInArrayWithSameId = currentFilterOptions.filter(object =>
    object[key].toString().includes(value)
  );
  return (
    value && value.toString().length > startSearchAtLength - 1 && currentObjectInArrayWithSameId.length >= 0
  );
}

export function buildKuerzelFilterArray(reisenFilterArray, kuerzelFilterArray) {
  const transformedReisekuerzelFilter = reisenFilterArray.map(filter => ({
    kuerzelId: filter.id,
    kuerzelLabel: filter.reisekuerzel,
    isReisekuerzel: true,
  }));

  if (kuerzelFilterArray.length === 0 && transformedReisekuerzelFilter.length === 0) {
    return null;
  } else {
    return transformedReisekuerzelFilter.concat(kuerzelFilterArray);
  }
}

export function buildKuerzelFilterOptionsArray(reisenFilterOptionsArray, kuerzelFilterOptionsArray) {
  const transformedReisekuerzelFilter =
    reisenFilterOptionsArray &&
    reisenFilterOptionsArray.map(filter => ({
      kuerzelId: filter.id,
      kuerzelLabel: filter.reisekuerzel,
      isReisekuerzel: true,
    }));

  const transformedReiseterminkuerzelFilter =
    kuerzelFilterOptionsArray &&
    kuerzelFilterOptionsArray.map(filter => ({
      kuerzelId: filter.id,
      kuerzelLabel: filter.reiseterminkuerzel,
      isReisekuerzel: false,
    }));

  if (
    Array.isArray(transformedReiseterminkuerzelFilter) &&
    transformedReiseterminkuerzelFilter.length !== 0 &&
    Array.isArray(transformedReisekuerzelFilter) &&
    transformedReisekuerzelFilter.length !== 0
  ) {
    return transformedReisekuerzelFilter.concat(transformedReiseterminkuerzelFilter);
  } else if (
    Array.isArray(transformedReiseterminkuerzelFilter) &&
    transformedReiseterminkuerzelFilter.length !== 0
  ) {
    return transformedReiseterminkuerzelFilter;
  } else if (Array.isArray(transformedReisekuerzelFilter) && transformedReisekuerzelFilter.length !== 0) {
    return transformedReisekuerzelFilter;
  } else {
    return [];
  }
}
export function isIdFilterRefreshable(id, currentFilterOptions, startSearchAtLength = 3) {
  // if id is empty set the options to an empty array
  // if the filter is greater than 2 digits start the search
  // if the search id is already in the options array do not search
  const currentObjectInArrayWithSameId = currentFilterOptions.filter(object =>
    object.id.toString().includes(id)
  );
  return id && id.toString().length > startSearchAtLength - 1 && currentObjectInArrayWithSameId.length >= 0;
}

export function parseFullUsername(user) {
  const surname = user.surname ? ' ' + user.surname : ''; // prepend empty space as a workaround to filter user without surname correctly
  return user ? `${user.givenName}${surname}` : '';
}

export function isUsernameMatching(user, username) {
  // compare usernames case insensitve (... will even filter if username is manual entry matches)
  return parseFullUsername(user).localeCompare(username, undefined, { sensitivity: 'base' }) === 0
    ? true
    : false;
}

export function getConfigStringsByObjectType(typeString) {
  return {
    stateObjName: 'reiseterminState',
    localeKey: typeString === 'reisetermin' || typeString === 'reisetermine' ? 'REISETERMINE' : 'VORGAENGE',
    route: typeString === 'reisetermin' || typeString === 'reisetermine' ? 'reisetermin' : 'vorgang',
    configString:
      typeString === 'reisetermin' || typeString === 'reisetermine' ? 'reisetermine' : 'vorgaenge',
  };
}

export function isReisetermin(obj) {
  return obj.hasOwnProperty('reiseterminkuerzel');
}

export function groupBy(data, key) {
  // groups an array of objects by the given key
  // key is case sensitive
  return data.reduce((accumulator, currentItem) => {
    let group = currentItem[key];
    accumulator[group] = accumulator[group] || [];
    accumulator[group].push(currentItem);
    return accumulator;
  }, {});
}

export function sortFluegeByAbflugdatumAndAbflugzeit(fluege) {
  // Sorts fluege by abflugdatum. If they are the same fall back to abflugzeit
  return fluege.sort((a, b) => {
    return (
      new Date(a.abflugdatum).valueOf() - new Date(b.abflugdatum).valueOf() || a.abflugzeit - b.abflugzeit
    );
  });
}

export function getDifferentFlights(newFlights, oldFlights) {
  // if new and old flights are there
  // return all old flights that have different abflugdatum or ankunftsdatum than
  // all new flights
  return (
    newFlights &&
    oldFlights &&
    oldFlights.filter(oldFlight => {
      return !newFlights.some(newFlight => {
        return (
          oldFlight.abflugdatum === newFlight.abflugdatum &&
          oldFlight.ankunftsdatum === newFlight.ankunftsdatum
        );
      });
    })
  );
}

export function parseEigenanreiseText(vorgang) {
  // Tries to parse eigenanreiseText
  const eigenanreiseText = vorgang.eigenanreiseText;
  const eigenanreiseRegEx =
    /(?<type>Ankunft|Ankunftszeit|Abflug|Abflugzeit):\s*(?<datum>(\d{2}).(\d{2}).(?!Uhr))*\s*(?<zeit>(2[0-4]|[0-1]?[\d])[:|.]([0-5][\d]))*\s*(Uhr)*[,|\\.]{0,1}\s*(?:(ab|in):\s*(?<flughafen>[A-Z]{3}))*\s*(Flugnummer|Flugnr)*\s*:\s*(?<flugnummer>(?<![\dA-Z])(?!\d{1,4})\s*([A-Z\d]{1,4})\s*?(\d{1,4})(?!\d))*/gm;
  const firstMatch = eigenanreiseRegEx.exec(eigenanreiseText);
  const secondMatch = eigenanreiseRegEx.exec(eigenanreiseText);
  const parsedObject = firstMatch
    ? secondMatch
      ? {
          [firstMatch.groups.type]: { ...firstMatch.groups },
          [secondMatch.groups.type]: { ...secondMatch.groups },
        }
      : { [firstMatch.groups.type]: { ...firstMatch.groups } }
    : null;
  return parsedObject;
}

export const changePeopleToCorrectShape = data => {
  return data
    .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
    .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
    .filter(user => user.jobTitle !== null)
    .filter(({ mail }) => mail !== null)
    .map(({ givenName, surname, id }) => ({
      value: id,
      text: givenName + ' ' + surname,
    }));
};

export const getRegionsmanagerByShortname = shortname => {
  const rm = Object.entries(regionsmanagerConfig).find(([key, value]) => value.shortname === shortname);
  return rm ?? null;
};

export const getRegionsmanagerOfReisekuerzel = reisekuerzel => {
  const regionsmanagerConfigStore = store.getters.getRegionsmanagerConfig;
  const responsibleRegionsmanager = Object.entries(regionsmanagerConfigStore).find(([key, value]) =>
    value.includes(reisekuerzel)
  )?.[0];
  const responsibleRegionsmanagerName = regionsmanagerConfig[responsibleRegionsmanager]?.shortname;
  return responsibleRegionsmanagerName;
};

export const getReisenOfRegionsmanager = shortname => {
  const regionsmanagerConfigStore = store.getters.getRegionsmanagerConfig;
  const regionsmanagerFromConfig = getRegionsmanagerByShortname(shortname);
  const idRegionsmanager = regionsmanagerFromConfig?.[0];
  const reisen = regionsmanagerConfigStore[idRegionsmanager];
  return reisen;
};

export const getRegionsmanagerById = id => {
  const rm = regionsmanagerConfig[id];
  return rm ?? null;
};

export const getAllReisenRegionsmanagers = () => {
  const regionsmanagerConfigStore = store.getters.getRegionsmanagerConfig;
  const alleReisen = Object.keys(regionsmanagerConfigStore).map(key => {
    const reisen = regionsmanagerConfigStore[key];
    return reisen;
  });
  return alleReisen;
};

export const triggerVariant = trigger => {
  const triggerFormatter = {
    TriggerInArbeit: 'warning',
    TriggerOffen: 'danger',
    TriggerErledigt: 'success',
    TriggerPending: 'info',
  };
  return triggerFormatter[trigger.trigger];
};
export const triggerLabel = (triggerName, isErgebnisFesthalten = null) => {
  if (isErgebnisFesthalten && triggerName === 'TriggerErledigt') return 'Ergebnis festhalten und erledigen';
  const triggerLabels = {
    TriggerInArbeit: 'In Arbeit',
    TriggerOffen: 'Offen',
    TriggerErledigt: 'Erledigt',
    TriggerPending: 'Pending',
  };
  return triggerLabels[triggerName];
};

export const getFlughaefenString = (item, key) => {
  if (!item || !item[key] || item[key].length === 0) {
    return '-';
  }
  if (item[key] && typeof item[key][0] === 'string') {
    return item[key].join(', ');
  } else {
    return (item[key] ?? []).map(flughafen => flughafen.flughafenIataCode).join(', ');
  }
};

export const permittedDepartments = [
  'Yield Fulfillment',
  'Yield Controlling',
  'Produktmanagement',
  'Destination Operations Management',
  'Flug',
  'Customer Success Management',
];

export const releaseprozessIconsColors = (iconAttribute, value) => {
  if (iconAttribute === 'fluegePassend') {
    return value;
  }
  if (iconAttribute === 'teilnehmerErledigt' || iconAttribute === 'reiseleistungListenversandErledigt') {
    return value === 'NichtGeprueft'
      ? 'text-danger'
      : value === 'GeprueftNichtKorrektInArbeit' || value === 'InArbeit'
      ? 'text-warning'
      : 'text-success';
  } else {
    return value ? 'text-success' : 'text-danger';
  }
};

export const releaseprozessIconClass = params => {
  const { attribute, value, icon } = params;
  return releaseprozessIconsColors(attribute, value) + ' mr-1 ' + icon;
};

export const kategorienSortRanking = {
  TempPruefungDurchfuehrung: 0,
  ReleasepruefungVorgange: 1,
  ReleasepruefungTermin: 2,
  NeueBuchung: 3,
  Transferplanung: 4,
  StornoOderFlugzeitenAenderung: 5,
};

export const checkDateInFuture = date => {
  if (date) {
    const selectedMoment = moment(date, 'YYYY-MM-DD');
    const today = moment().subtract(1, 'days');

    return selectedMoment.isAfter(today, 'day');
  } else {
    return false;
  }
};

export const exactlySixChars = value => {
  return value !== null && value.length === 6;
};
export const noDigits = value => {
  return value !== null && !/\d/.test(value);
};

export const transferszenarioOptions = [
  { text: 'TX', value: 'TX_ohne' },
  { text: 'T1', value: 'T1_1inkl' },
  { text: 'T2', value: 'T2_1inkl_exkl_mögl' },
  { text: 'T3', value: 'T3_unbegr_inkl' },
  { text: 'T4', value: 'T4_unbegr_exkl' },
  { text: 'T5', value: 'T5_fest_inkl' },
  { text: 'T6', value: 'T6' },
  { text: 'T5_2', value: 'T5_2' },
  { text: 'T5_3', value: 'T5_3' },
  { text: 'TS', value: 'TS' },
];

export const langConfig = {
  formatLocale: {
    months: moment.localeData().months(),
    monthsShort: moment.localeData().monthsShort(),
    weekdays: moment.localeData().weekdays(),
    weekdaysShort: moment.localeData().weekdaysShort(),
    weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    firstDayOfWeek: 1,
  },
};
export const ergebnisOptions = [
  {
    value: 'ZusaetzlicheZimmerErhaltenGleicherPreis',
    text: 'Zusätzliche Zimmer erhalten - gleicher Preis',
  },
  {
    value: 'ZusaetzlicheZimmerErhaltenAufpreisEz',
    text: 'Zusätzliche Zimmer erhalten – Aufpreis (EZ)',
  },
  {
    value: 'ZusaetzlicheZimmerNichtAusreichendGleicherPreis',
    text: 'Zusätzliche Zimmer, aber nicht ausreichend, erhalten - gleicher Preis',
  },
  {
    value: 'ZusaetzlicheZimmerNichtAusreichendAufpreis',
    text: 'Zusätzliche Zimmer, aber nicht ausreichend, erhalten - Aufpreis (EZ)',
  },
  {
    value: 'ZimmerkontingentPassivGeschaffen',
    text: 'Zimmerkontingent wurde passiv geschaffen',
  },
  { value: 'KeineZimmerErhaltenStopSale', text: 'Keine Zimmer erhalten Stop-Sale' },
  { value: 'KeineZimmerErhaltenNurAufRq', text: 'Keine Zimmer erhalten - nur auf RQ' },
  {
    value: 'KeineZimmerErhaltenAberZusatzterminAufgelegt',
    text: 'Keine Zimmer erhalten - aber Zusatztermin aufgelegt',
  },
  {
    value: 'VerfügbarkeitNichtGeschaffenKurzfristigkeit',
    text: 'Verfügbarkeit nicht geschaffen - Kurzfristigkeit',
  },
  {
    value: 'VerfügbarkeitNichtGeschaffenKleineHotelgroesse',
    text: 'Verfügbarkeit nicht geschaffen - Kleine Hotelgröße',
  },
  {
    value: 'VerfügbarkeitNichtGeschaffenHsFestival',
    text: 'Verfügbarkeit nicht geschaffen - HS/Festival (Saisonalität)',
  },
  {
    value: 'GruppeGehalten',
    text: 'Gruppe gehalten',
  },
  {
    value: 'GruppeZurueckgegeben',
    text: 'Gruppe zurückgegeben',
  },
  {
    value: 'TicketingMoeglich',
    text: 'TKT möglich',
  },
  {
    value: 'TicketingAbgelehnt',
    text: 'TKT abgelehnt',
  },
  {
    value: 'VerfuegbarkeitNichtGeschaffenCmKontaktUnerwuenscht',
    text: 'Verfügbarkeit nicht geschaffen – CM Kontakt unerwünscht',
  },
  {
    value: 'KeineZimmerErhaltenKeineRueckmeldungVomCm',
    text: 'Keine Zimmer erhalten – Keine Rückmeldung vom CM',
  },
  {
    value: 'KeineZimmerAngefragtErwPaxZuValidieren',
    text: 'Keine Zimmer  angefragt  - Erw. Pax zu validieren',
  },
  { value: 'ZusatztermineAngefragtUndBestaetigt', text: 'Zusatztermine angefragt & bestätigt' },
  { value: 'ZusatztermineAngefragtUndAbgelehnt', text: 'Zusatztermine angefragt & abgelehnt' },
  { value: 'ZusatztermineNichtAngefragt', text: 'Zusatztermine nicht angefragt' },
];

export const einkaufsartOptions = [
  { text: '-', value: null },
  { text: 'EP', value: 'Ep' },
  { text: 'Backup geplant', value: 'BackupGeplant' },
  { text: 'Backup bestätigt', value: 'BackupBestaetigt' },
  { text: 'EP BU zurück', value: 'EpBackupZurueck' },
  { text: 'EP BU nicht bestätigt', value: 'EpBackupNichtBestaetigt' },
  { text: 'RES geplant', value: 'RestriktivGeplant' },
  { text: 'RES bestätigt/buchbar', value: 'RestriktivBestaetigtBuchbar' },
  { text: 'EP RES zurück', value: 'EpResZurueck' },
  { text: 'EP RES nicht bestätigt', value: 'EpResNichtBestaetigt' },
  { text: 'RES statt BU', value: 'RestriktivStattBackup' },
  { text: 'Flex geplant', value: 'FlexGeplant' },
  { text: 'Flex buchbar', value: 'FlexBuchbar' },
  { text: 'EP Flex nicht bestätigt', value: 'EpFlexNichtBestaetigt' },
  { text: 'EP Flex zurück', value: 'EpFlexZurueck' },
  { text: 'Leerverkauf Flex', value: 'LeerverkaufFlex' },
  { text: 'Leerverkauf RES', value: 'LeerverkaufRes' },
  { text: 'Leerverkauf kurzes TKTL', value: 'LeerverkaufKurzesTktl' },
];

export const statusgruppeOptions = [
  { text: '-', value: null },
  { text: 'Angefragt', value: 'Angefragt' },
  { text: 'Gruppe geplant', value: 'GruppeGeplant' },
  { text: 'Checked ok', value: 'CheckedOk' },
  { text: 'Checked ok teurer Kalk', value: 'CheckedOkTeurerKalk' },
  { text: 'Angelegt', value: 'Angelegt' },
  { text: 'Angelegt, Anpassung nötig', value: 'AngelegtAnpassungNoetig' },
  { text: 'EP nach GRP Opt', value: 'EpNachGruppeOptional' },
  { text: 'EP nach GRP sonstiges', value: 'EpNachGruppeSonstiges' },
];

export const grundOptions = [
  { text: 'Keine Bestätigung CM', value: 'KeineBestaetigungCm' },
  { text: 'Keine Flugverfügbarkeit', value: 'KeineFlugverfuegbarkeit' },
  { text: 'Saisonales Ereignis', value: 'SaisonalesEreignis' },
  { text: 'Verschiebung aufgrund von Planüberprüfung', value: 'VerschiebungPlanueberpruefung' },
  { text: 'Sonstiges', value: 'Sonstiges' },
];

export const verworfenGrundToLabel = grundOptions.reduce((acc, curr) => {
  acc[curr.value] = curr.text;
  return acc;
}, {});

function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export function arraysEqualUnordered(arr1, arr2) {
  if (
    typeof arr1 === 'string' ||
    typeof arr2 === 'string' ||
    typeof arr1 === 'number' ||
    typeof arr2 === 'number'
  )
    return false;
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (!deepEqual(sortedArr1[i], sortedArr2[i])) return false;
  }

  return true;
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function getBlankSyncState(enumValue) {
  const translation = {
    AvailableInBlank: 'Angelegt',
    NotAvailableInBlank: 'Nicht angelegt',
    None: 'Nicht synchronisiert',
    AvailableInBlankAndSyncedHotelleistungWithRelations: 'Angelegt',
    AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingente: 'Angelegt',
    AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingenteAndZusatzleistungen: 'Angelegt',
  };

  return translation[enumValue];
}

export function getInBlankAngelegt(reiseterminSyncState) {
  if (reiseterminSyncState === 'None') {
    return 'Nicht synchronisiert';
  } else if (reiseterminSyncState === 'NotAvailableInBlank') {
    return 'Nicht in Blank angelegt';
  }

  return 'In Blank angelegt';
}

export const textbausteine = {
  toDosBeiGpr: 'To Dos bei GRP halten: Namen melden, Konti melden, Reduzierung GRP-FS, HiHi ggf. öffnen',
  toDosBeiGruppe:
    'To Dos bei Gruppe zurückgeben: 3er GRP FS sperren, HiHi öffnen, Status & Einkaufsart anpassen, im FVC Akzeptanz im Bearbeitungsstatus entfernen',
  zimmerkontingentePruefen:
    'Bitte erhöhe das Zimmerkontingent wie in der zugehörigen Aufgabe “Zimmerkontingente prüfen” hinterlegt.',
  KurzfristigeFlugzeitenaenderung:
    '@Yield: Aufgabe an DOM nutzen wenn FZÄ > 15 min & KD schon unterwegs ODER HF FZÄ <= 4 Tage vor Abreise <br/> <br/> @DOM: Bitte informiert den CM über die FZÄ. <br/> <br/> Flug alt: XY <br/> <br/> Flug neu: XY',
  ZusatzterminePruefen:
    'Bitte prüft anhand der folgenden Informationen, ob ein Zusatztermin angefragt werden sollte:',
};

export const flugstammdatenAufgabenKategorien = [
  'AnlageFlugstamm',
  'TrfPreisAenderung',
  'ThVkAenderung',
  'KalkulationstabellePruefen',
  'FhAenderung',
  'ResAenderung',
  'TrfSzenarioAenderung',
  'Terminverschiebung',
  'OvernightAenderung',
  'NPruefung',
];

export const flugplanungAufgabenKategorien = ['OptionsfristErreicht'];

export function vorzeitigGarantiertClass(vorzeitigGarantiert) {
  const vorzeitigGarantiertConverter = {
    VorzeitigGarantiert: 'fas fa-check text-success fa-lg',
    NichtVorzeitigGarantiert: 'fas fa-times text-danger fa-lg',
    WarVorzeitigGarantiert: 'fas fa-check text-danger fa-lg',
  };
  return vorzeitigGarantiertConverter[vorzeitigGarantiert];
}
export const transferdauerOptions = [
  30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480, 510, 540, 570, 600,
];

const DepartmentButtonClass = {
  Produktmanagement: {
    label: 'PM',
    color: '#1BC5BD',
    fontColor: 'white',
  },
  'Yield Fulfillment': {
    label: 'YF',
    color: '#FFA800',
    fontColor: 'white',
  },
  'Destination Operations Management': {
    label: 'DOM',
    color: '#F64E60',
    fontColor: 'white',
  },
  Flug: {
    label: 'Flug',
    color: '#3699FF',
    fontColor: 'white',
  },
  'Customer Success Management': {
    label: 'CSM',
    color: '#8950FC',
    fontColor: 'white',
  },
  Tech: {
    label: 'Tech',
    color: '#212121',
    fontColor: 'white',
  },
  'Yield Controlling': {
    label: 'YC',
    color: '#C8A2C8',
    fontColor: 'white',
  },
};

export function departmentButtonClass(department) {
  return DepartmentButtonClass[department] || {};
}

export function formatAndCalculateReleasedatum(datum, releasefrist) {
  const startdatum = new Date(datum);
  const generatedDatum = subDays(startdatum, releasefrist);
  return format(generatedDatum, 'dd.MM.yyyy');
}
export function translateStatusGruppeOptions(value) {
  return statusgruppeOptions.find(option => option.value === value)?.text ?? '-';
}

export function translateEinkaufsartOptions(value) {
  return einkaufsartOptions.find(option => option.value === value)?.text ?? '-';
}

export const statusFormatterHtml = {
  InArbeit:
    '<span class="badge badge-secondary" style="padding: 4px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-warning p-1"><i class="far fa-1x fa-clock" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">In Arbeit</span></span>',
  Erledigt:
    '<span class="badge badge-secondary" style="padding: 4px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-success p-1"><i class="fas fa-1x fa-check" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">Erledigt</span></span>',
  Pending:
    '<span class="badge badge-secondary" style="padding: 4px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-info p-1"><i class="fas fa-1x fa-clock" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">Pending</span></span>',
  Offen:
    '<span class="badge badge-secondary" style="padding: 4px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-danger p-1"><i class="fas fa-1x fa-exclamation" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">Offen</span></span>',
};

export const paxFilterFormatterSmallHtml = {
  'Ist-Pax = 0':
    '<span class="badge badge-secondary p-1" style="padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax = 0</span>',
  'Ist-Pax > 0':
    '<span class="badge badge-primary p-1" style="padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax > 0</span>',
  'Ist-Pax = 1':
    '<span class="badge badge-danger p-1" style="background-color: ##ba4753;padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax = 1</span>',
  'Ist-Pax = Max-Pax':
    '<span class="badge badge-secondary p-1" style="color: white; background-color: #159892; padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax = Max-Pax</span>',
  'Ist-Pax < MTN-Pax':
    '<span class="badge p-1 badge-danger" style="padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax &lt; MTN-Pax</span>',
  'Ist-Pax ≥ MTN-Pax':
    '<span class="badge badge-warning p-1" style="padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax ≥ MTN-Pax</span>',
  'Ist-Pax = MTN-1':
    '<span class="badge badge-secondary p-1" style="background-color: #002fff; color: white; padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax = MTN-1</span>',
  'Ist-Pax = MTN-2':
    '<span class="badge badge-secondary p-1" style="background-color: #9f2b68; color: white; padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax = MTN-2</span>',
  'Ist-Pax = Kalk-Pax':
    '<span class="badge badge-secondary p-1" style="background-color: #1BC5BD; color: white; padding: 1px; display: flex; align-items:center; font-size:1rem" >Ist-Pax = Kalk-Pax</span>',
};

export const statusFormatterSmallHtml = {
  InArbeit:
    '<span class="badge badge-secondary" style="padding: 1px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-warning p-1"><i class="far fa-1x fa-clock" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">In Arbeit</span></span>',
  Erledigt:
    '<span class="badge badge-secondary" style="padding: 1px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-success p-1"><i class="fas fa-1x fa-check" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">Erledigt</span></span>',
  Pending:
    '<span class="badge badge-secondary" style="padding: 1px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-info p-1"><i class="fas fa-1x fa-clock" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">Pending</span></span>',
  Offen:
    '<span class="badge badge-secondary" style="padding: 1px; display:flex; align-items:center; font-size: 1rem"><span class="badge badge-danger p-1"><i class="fas fa-1x fa-exclamation" style="width: 15px"></i></span><span class="ml-2 w-100" style="text-align: center">Offen</span></span>',
};

export const prioritaetFormatterHtml = {
  2: '<span class="badge badge-secondary" style="padding: 4px; font-size: 1rem;"><span class="badge badge-danger p-1"><i class="fas fa-1x fa-arrow-up"></i></span><span class="ml-2">Hoch</span></span>',
  1: '<span class="badge badge-secondary" style="padding: 4px; font-size: 1rem;"><span class="badge badge-warning p-1"><i class="fas fa-1x fa-arrow-right"></i></span><span class="ml-2">Mittel</span></span>',
  0: '<span class="badge badge-secondary" style="padding: 4px; font-size: 1rem;"><span class="badge badge-success p-1"><i class="fas fa-1x fa-arrow-down"></i></span><span class="ml-2" >Niedrig</span></span>',
};

export const prioritaetFormatterSmallHtml = {
  2: '<span class="badge badge-secondary" style="padding: 1px; font-size: 1rem;"><span class="badge badge-danger p-1"><i class="fas fa-1x fa-arrow-up"></i></span><span class="ml-2">Hoch</span></span>',
  1: '<span class="badge badge-secondary" style="padding: 1px; font-size: 1rem;"><span class="badge badge-warning p-1"><i class="fas fa-1x fa-arrow-right"></i></span><span class="ml-2">Mittel</span></span>',
  0: '<span class="badge badge-secondary" style="padding: 1px; font-size: 1rem;"><span class="badge badge-success p-1"><i class="fas fa-1x fa-arrow-down"></i></span><span class="ml-2" >Niedrig</span></span>',
};

export const reiseterminStateFormatter = {
  Abgesagt: 'Abgesagt',
  Anlagebereit: 'Anlagebereit',
  Aufgelegt: 'Aufgelegt',
  CmAngefragt: 'CM angefragt',
  CmBestaetigt: 'CM bestätigt',
  Durchfuehrung: 'Durchführung',
  Durchgefuehrt: 'Durchgeführt',
  Verworfen: 'Verworfen',
  Vorgeschlagen: 'Vorgeschlagen',
};
